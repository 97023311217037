import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            itemId: 0,
            voucherInfo: ''
        };
    };
    render() {
        const { content } = this.state;
        const { voucherCode } = this.props;
        return (
            <Fragment>
                <Helmet>
                    <title>{content.metaTitle}</title>
                    <meta name="description" content={content.metaDescription} />
                    <meta name="robots" content={content.metaRobots} />
                    <meta name="googlebot" content={content.metaGoogleBot} />
                    <meta name="keywords" content={content.metaKeywords} />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="container-fluid bg-light py-4">
                    <div className="container">
                        <div className="row justify-content-sm-center">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="container">
                                            <div className="row py-4">
                                                <div className="col-12 text-center">
                                                    <img className="img-fluid" src="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086" srcSet="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x, //cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x" height="60" alt="Overnight Flowers" itemProp="logo" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 bg-purple text-white text-center py-4">
                                                    <h3>Thank you for your business!</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 text-center py-4">
                                                    <p>Your voucher code is now redeemable on<br />
                                                    <a href="https://www.overnightflowers.co">www.OvernightFlowers.com</a></p>
                                                </div>
                                                <div className="col-12 bg-dark text-light text-center py-5">
                                                    Voucher Code : {voucherCode}
                                                </div>
                                                <div className="col-12 text-center pt-4">
                                                    <p>To redeem your voucher simply copy and paste<br />
                                                    the above voucher code into the<br />
                                                    "promo code" section on the checkout page.</p>
                                                </div>
                                                <div className="col-12 bg-light text-dark text-center py-4">
                                                    <a href="https://www.overnightflowers.com" class="btn btn-warning">REDEEM NOW</a>
                                                </div>
                                                <div className="col-12 text-center small pt-4">
                                                    <p>*This voucher cannot be used in conjunction<br />
                                                    with any other products or any promotions.<br />
                                                    ** Limit one bouquet per customer.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ContentPage