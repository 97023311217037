import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import store from './client/store';
import {PageView, initGA} from './components/tracking/GATracking';
import { GACode } from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/site.min.css';
import "react-image-gallery/styles/css/image-gallery.css";

// TEMPLATES
import HeaderTemplate from './components/layout/Header';
import FooterTemplate from './components/layout/Footer';

// Pages
import HomePage from './components/pages/HomePage';
import Voucher from './components/pages/Voucher';
import Confirmation from './components/pages/Confirmation';
import Checkout from './components/pages/Checkout';
import Error404 from './components/pages/Error404';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [
                {
                    pageType: "homepage",
                    linkUrl: "/",
                    linkName: "Home"
                },
                {
                    pageType: "confirmation",
                    linkUrl: "/confirmation",
                    linkName: "Confirmation"
                },
                {
                    pageType: "homepage",
                    linkUrl: "/alpham",
                    linkName: "Home"
                },
                {
                    pageType: "voucher",
                    linkUrl: "/alpham-archived",
                    linkName: "AlphaM",
                    title: "The Overnight Flowers x Alpha M Deal",
                    headerImage: "/images/alpham-header.jpg",
                    items: [
                        {
                            title: "Petite Bouquet",
                            list1: "guaranteed pricing",
                            list2: "vase",
                            list3: "free shipping",
                            price: "39.99",
                            image: "/images/item-petite.png"
                        },
                        {
                            title: "One Luxe Bouquet",
                            list1: "One Luxe Bouquet (our largest size)",
                            list2: "FREE vase",
                            list3: "FREE next day delivery",
                            list4: "61% Savings",
                            list5: "Voucher redeemable thru 6/30/2020",
                            priceRetail: "124.98",
                            price: "47.99",
                            image1: "/images/alpham-image1.jpg",
                            image2: "/images/alpham-image2.jpg",
                            image3: "/images/alpham-image3.jpg",
                            image4: "/images/alpham-image4.jpg"
                        }
                    ],
                    description: "",
                    helpfulInformation: '<ul>' +
                        '<li>One voucher includes any Luxe bouquet, free vase, and free next day delivery</li>' +
                        '<li>No blackout dates</li>' +
                        '<li>Cannot be combined with any other offers</li>' +
                        '<li>Voucher expires on June 30th, 2020</li>' +
                        '<li>Does not ship to PO Boxes/AK/HI/PR/Outside the United States</li>' +
                        '<li>Any questions, please send to <a href="mailto: support@overnightflowers.co">support@overnightflowers.com</a></li>' +
                        '</ul>',
                    step1: 'Lock in savings now with our best deal of the year',
                    step2: 'Redeem your deal thru June 30th, 2020',
                    step3: 'Receive your hand-tied bouquet with free overnight shipping',
                    agreementStatement: "I understand that I am purchasing a voucher code that allows me to purchase a bouquet from OvernightFlowers.com at a discounted price. My code will remain valid from 12/1/2019 thru 6/30/2020."
                }
            ],
            config: {
                id:1
            },
        };
    };
    componentDidMount() {
        initGA(GACode);
        PageView();
    }
    render() {
        const { routes, config } = this.state;
        if (typeof(config.id) !== 'undefined') {
            return (
                <Provider store={store}>
                    <Router>
                        <Fragment>
                            <HeaderTemplate routes={routes} pageConfig={config}/>
                            <Switch>
                                {routes.map((route, index) => {
                                    let routeType = '';
                                    if (route.pageType === '' || typeof (route.pageType) === 'undefined') {
                                        routeType = "content";
                                    } else {
                                        routeType = route.pageType;
                                    }
                                    if (routeType === 'homepage') {
                                        return (<Route
                                            key={index}
                                            exact
                                            path={route.linkUrl}
                                            component={HomePage}
                                            pageConfig={config}
                                            location={this.props.location}
                                        />);
                                    } else if (routeType === 'voucher') {
                                        return (<Route
                                            key={index}
                                            exact
                                            path={route.linkUrl}
                                            component={(props) => <Voucher
                                                {...props}
                                                voucherId={index}
                                                voucherInfo={route}
                                                pageConfig={config}
                                                location={this.props.location}
                                            />}
                                        />);
                                    } else if (routeType === 'confirmation') {
                                        return (<Route
                                            key={index}
                                            exact
                                            path={route.linkUrl}
                                            component={(props) => <Confirmation
                                                {...props}
                                                voucherId={index}
                                                voucherInfo={route}
                                                pageConfig={config}
                                                location={this.props.location}
                                            />}
                                        />);
                                    } else {
                                        return (null);
                                    }
                                })}


                                <Route
                                    exact
                                    path="/purchase/:voucherCode/:itemId"
                                    component={(props) => <Checkout
                                        {...props}
                                        voucherInfo={routes}
                                        pageConfig={config}
                                        location={this.props.location}
                                    />}
                                />
                                <Route component={Error404} pageConfig={config} />
                            </Switch>
                            <FooterTemplate routes={routes} pageConfig={config} />
                        </Fragment>
                    </Router>
                </Provider>
            );
        } else {
            return (null);
        }
    }
}

export default App;
