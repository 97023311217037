import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: []
        };
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState === this.state) {

        }
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.location.href = "https://www.overnightflowers.com";
    }

    render() {
        const { content } = this.state;
        console.log("homepage render", this.props);

        return (
            <Fragment>
                <Helmet>
                    <title>{content.metaTitle}</title>
                    <meta name="description" content={content.metaDescription} />
                    <meta name="robots" content={content.metaRobots} />
                    <meta name="googlebot" content={content.metaGoogleBot} />
                    <meta name="keywords" content={content.metaKeywords} />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="container-fluid bg-light py-5">
                    <div className="container rounded bg-white py-5">
                        <div className="row">
                            <div className="col-12">
                                <a href="/alpham">Alpha M Voucher</a>
                                <div dangerouslySetInnerHTML={{__html: content.pageContent}} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ContentPage