import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import PaymentForm from "./PaymentForm";
import Confirmation from './Confirmation';

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            itemId: 0,
            voucherInfo: '',
            showConfirmation: 0,
            voucherCode:''
        };
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const { itemId, voucherCode } = this.props.match.params;

        this.setState({itemId:itemId, voucherInfo:this.props.voucherInfo[voucherCode]});

        const that = this;
        let sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
        sqPaymentScript.type = "text/javascript";
        sqPaymentScript.async = false;
        sqPaymentScript.onload = ()=>{that.setState({
            loaded: true
        })};
        document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
    }

    showConfirmationCode = (voucherCode) => {
      this.setState({showConfirmation: 1, voucherCode: voucherCode})
    };

    render() {
        const { content, itemId, showConfirmation, voucherCode } = this.state;
        const { title, items } = this.state.voucherInfo;

        if (showConfirmation === 0) {
            return (
                <Fragment>
                    <Helmet>
                        <title>{content.metaTitle}</title>
                        <meta name="description" content={content.metaDescription} />
                        <meta name="robots" content={content.metaRobots} />
                        <meta name="googlebot" content={content.metaGoogleBot} />
                        <meta name="keywords" content={content.metaKeywords} />
                        <meta name="theme-color" content="#008f68" />
                    </Helmet>
                    <div className="container-fluid bg-light py-4">
                        {itemId &&
                        <div className="container-xl">
                            <div className="row justify-content-md-center mt-2 text-left">
                                <div className="col-sm-8 my-2">
                                    <div className="card voucher">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6 p-3 px-5">
                                                    <h3 className="h2 py-3">{title}</h3>
                                                    <ul className="px-0">
                                                        <li>{items[1].list1}</li>
                                                        <li>{items[1].list2}</li>
                                                        <li>{items[1].list3}</li>
                                                        <li>{items[1].list4}</li>
                                                        <li>{items[1].list5}</li>
                                                    </ul>
                                                    <h3 className="h5 text-center">Retail Price: <span style={{textDecoration: 'line-through'}}>${items[1].priceRetail}</span></h3>
                                                    <h3 className="h2 text-center text-red">${items[1].price} <span className="badge badge-danger">61% OFF</span></h3>
                                                </div>
                                                <div className="col-sm-6 no-mobile">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-6 p-1">
                                                                <img src={items[1].image1}
                                                                     alt={"image of " + items[1].title}
                                                                     className="img-fluid"/>
                                                            </div>
                                                            <div className="col-6 p-1">
                                                                <img src={items[1].image2}
                                                                     alt={"image of " + items[1].title}
                                                                     className="img-fluid"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6 p-1">
                                                                <img src={items[1].image3}
                                                                     alt={"image of " + items[1].title}
                                                                     className="img-fluid"/>
                                                            </div>
                                                            <div className="col-6 p-1">
                                                                <img src={items[1].image4}
                                                                     alt={"image of " + items[1].title}
                                                                     className="img-fluid"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5 my-2">
                                    <div className="card">
                                        <div className="card-body">

                                            <h3 className="mb-4">Purchase Your Voucher Code</h3>

                                            {this.state.loaded && <PaymentForm
                                                paymentForm={ window.SqPaymentForm }
                                                voucherInfo={this.state.voucherInfo}
                                                itemName={items[1].title}
                                                price={items[itemId].price}
                                                showConfirmationCode={this.showConfirmationCode}
                                            />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Confirmation
                    voucherCode={voucherCode}
                />
            )
        }
    }
}

export default ContentPage