import React, {Component} from 'react';
class Footer extends Component {
    render() {
        const { pageConfig } = this.props;
        if (pageConfig !== null)
        {
            return (
                <footer>
                    <div className="container-fluid py-5 text-white" style={{ backgroundColor: "#7a4f9e"}}>
                        <div className="container-xl">
                            <div className="row">
                                <div className="col-sm-4">
                                    <ul className="list-unstyled text-small">
                                        <li><a className="text-white" href="https://www.overnightflowers.com/account/login">Account</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/pages/faq">FAQ</a></li>
                                        <li><a className="text-white"href="https://www.overnightflowers.compages/terms-of-use">Terms Of Use</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/pages/privacy-policy">Privacy Policy</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/pages/floral-care-guide">Floral Care Guide</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/blogs/onf-blog">Blog</a></li>
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <ul className="list-unstyled text-small">
                                        <li><a className="text-white" href="https://www.overnightflowers.com/collections/all-products">Shop</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/pages/fall-collection">Fall Collection</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/collections/assortments">Mixed Bouquets</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/collections/roses">Roses</a></li>
                                        <li><a className="text-white" href="https://www.overnightflowers.com/pages/the-onf-story">About Us</a></li>
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <p>Let's Connect</p>
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://www.instagram.com/overnightflowers/"
                                           title="Overnight Flowers on Instagram"
                                        >
                                            <svg aria-hidden="true" focusable="false" role="presentation"
                                                 viewBox="0 0 32 32" style={{
                                                width: 32,
                                                height: 32
                                            }}>
                                                <path fill="#fff" d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z" />
                                                <path fill="#fff" d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z" />
                                            </svg>
                                        </a>
                                        &nbsp;
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://www.facebook.com/FamouslyFreshFlowers/"
                                           title="Overnight Flowers on Facebook"
                                        >
                                            <svg aria-hidden="true" focusable="false" role="presentation" viewBox="0 0 32 32"
                                                 style={{
                                                width: 32,
                                                height: 32
                                            }}>
                                                <path fill="#fff" d="M18.56 31.36V17.28h4.48l.64-5.12h-5.12v-3.2c0-1.28.64-2.56 2.56-2.56h2.56V1.28H19.2c-3.84 0-7.04 2.56-7.04 7.04v3.84H7.68v5.12h4.48v14.08h6.4z" />
                                            </svg>
                                        </a>
                                    </p>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-right">
                                    <p className="small">© 2019 Overnight Flowers | All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

export default Footer;