import React, { Component } from 'react';
import {
    apiKey,
    apiURL,
    squareApplicationId,
    squareLocationId
} from "../../config";
import axios from "axios";

export default class PaymentForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            cardBrand: "",
            nonce: undefined,
            googlePay: false,
            applePay: false,
            masterpass: false
        };
        this.requestCardNonce = this.requestCardNonce.bind(this);
    }
    requestCardNonce(){
        let voucherAgreement = document.getElementById("voucherAgreement").checked;
        if (voucherAgreement) {
            this.paymentForm.requestCardNonce();
        } else {
            document.getElementById("cardErrors").style.display = "block";
            document.getElementById("cardErrors").innerHTML = "You must agree to our terms to continue purchasing this voucher.";
        }
    }

    processPayment(nonce, cardData, price) {
        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        axios.post(apiURL + 'processPayment', {
                name: name,
                email: email,
                nonce: nonce,
                cardData: cardData,
                itemName: this.props.itemName,
                price: price,
                apiKey: apiKey
            })
            .then( (response) => {
                if (response.data.Status === "1" || response.data.Status === 1) {
                    this.props.showConfirmationCode(response.data.voucherCode);
                } else {
                    console.log("ERROR WITH WEB PULL", response.data.ErrorBody.errors[0]);
                    document.getElementById("cardErrors").style.display = "block";

                    if (response.data.ErrorBody.errors[0].code === 'PAN_FAILURE') {
                        document.getElementById("cardErrors").innerHTML = "Card was not authorized!<br/>Check the card number, the billing zip and try again.";
                    } else {
                        document.getElementById("cardErrors").innerHTML = response.data.ErrorBody.errors[0].detail;
                    }

                }
            })
            .catch(function (error) {
                console.log("ERROR", error);
            });
    }

    componentDidMount(){
        const config = {
            applicationId: squareApplicationId,
            locationId: squareLocationId,

            inputClass: "form-control",
            autoBuild: false,
            cardNumber: {
                elementId: "sq-card-number",
                className: "form-control",
                placeholder: "• • • •  • • • •  • • • •  • • • •"
            },
            cvv: {
                elementId: "sq-cvv",
                placeholder: "cvv"
            },
            expirationDate: {
                elementId: "sq-expiration-date",
                placeholder: "mm/yy"
            },
            postalCode: {
                elementId: "sq-postal-code",
                placeholder: "zip code"
            },
            callbacks: {
                methodsSupported: (methods) => {
                    if(methods.googlePay){
                        this.setState({
                            googlePay: methods.googlePay
                        })
                    }
                    if(methods.applePay){
                        this.setState({
                            applePay: methods.applePay
                        })
                    }
                    if(methods.masterpass){
                        this.setState({
                            masterpass: methods.masterpass
                        })
                    }
                    return;
                },
                createPaymentRequest: () => {
                    return {
                        requestShippingAddress: false,
                        requestBillingInfo: true,
                        currencyCode: "USD",
                        countryCode: "US",
                        total: {
                            label: "OvernightFlowers.com",
                            amount: 1,
                            pending: false
                        },
                        lineItems: [
                            {
                                label: "Voucher Purchase",
                                amount: 1,
                                pending: false
                            }
                        ]
                    };
                },
                cardNonceResponseReceived: (errors, nonce, cardData) => {
                    if (errors) {
                        let textErrors = '';
                        // Log errors from nonce generation to the Javascript console
                        console.log("Encountered errors:");
                        errors.forEach(function(error) {
                            console.log("  " + error.message);
                            textErrors += error.message + "<br />";
                        });
                        document.getElementById("cardErrors").style.display = "block";
                        document.getElementById("cardErrors").innerHTML = textErrors;
                        return;
                    } else {
                        document.getElementById("cardErrors").style.display = "none";
                        document.getElementById("cardErrors").innerHTML = '';
                        this.processPayment(nonce, cardData, this.props.price);
                    }
                    this.setState({
                        nonce: nonce
                    })
                },
                unsupportedBrowserDetected: () => {},
                inputEventReceived: (inputEvent) => {
                    switch (inputEvent.eventType) {
                        case "focusClassAdded":
                            break;

                        case "focusClassRemoved":
                            break;

                        case "errorClassAdded":
                            document.getElementById("error").style.display = "block";
                            document.getElementById("error").innerHTML = "Please fix card information errors before continuing.";
                            break;

                        case "errorClassRemoved":
                            document.getElementById("error").style.display = "none";
                            break;

                        case "cardBrandChanged":
                            if(inputEvent.cardBrand !== "unknown"){
                                this.setState({
                                    cardBrand: inputEvent.cardBrand
                                })
                            } else {
                                this.setState({
                                    cardBrand: ""
                                })
                            }
                            break;

                        case "postalCodeChanged":
                            break;

                        default:
                            break;
                    }
                },
                paymentFormLoaded: function() {}
            }
        };

        this.paymentForm = new this.props.paymentForm(config);
        this.paymentForm.build();
    }

    render(){
        return (
            <div id="sq-ccbox">

                <h4>Personal Information</h4>
                <div className="form-group">
                    <label htmlFor="inputAddress">Full Name</label>
                    <input type="text" className="form-control" id="name" name="name" placeholder="enter full name" />
                </div>
                <div className="form-group">
                    <label htmlFor="inputAddress">Email</label>
                    <input type="text" className="form-control" id="email" name="email" placeholder="enter email address" />
                </div>

                <hr noshade='true' />
                <h4>Payment Information</h4>

                <div className="form-group">
                    <label htmlFor="inputAddress">Credit Card Number</label>
                    <div id="sq-card-number" />
                </div>
                <div className="form-row">
                    <div className="col">
                        <label htmlFor="inputAddress">Exp Date</label>
                        <div id="sq-expiration-date" />
                    </div>
                    <div className="col">
                        <label htmlFor="inputAddress">CVV</label>
                        <div id="sq-cvv" />
                    </div>
                    <div className="col">
                        <label htmlFor="inputAddress">Billing Zip</label>
                        <div id="sq-postal-code" />
                    </div>
                </div>
                <input type="hidden" id="card-nonce" name="nonce" />
                <div className="row">
                    <div className="col-12 text-center">
                        <img src="/images/square.png" alt="cards accepted" className="img-fluid my-5" />
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="voucherAgreement" name="voucherAgreement" />
                        <label className="form-check-label" htmlFor="voucherAgreement">
                            {this.props.voucherInfo.agreementStatement}
                        </label>
                    </div>
                </div>
                <h3 className="my-4 text-purple text-center">Cost : ${this.props.price}</h3>
                <button className="btn btn-block btn-success my-3" onClick={this.requestCardNonce}>Submit Payment</button>
                <div className="alert alert-danger mt-2" id="error" style={{display: 'none'}} />
                <div className="alert alert-warning mt-2" id="cardErrors" style={{display: 'none'}} />
            </div>
        )
    }
}