import React, {Component, Fragment} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

class ContentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: {
                metaTitle: "Overnight Flowers x alpha m. Voucher",
                metaKeywords: "",
                metaDescription: "",
                metaRobots: "",
                metaGoogleBot: ""
            }
        };
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        const { title, headerImage, items, helpfulInformation, step1, step2, step3 } = this.props.voucherInfo;
        const { content } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>{content.metaTitle}</title>
                    <meta name="description" content={content.metaDescription} />
                    <meta name="robots" content={content.metaRobots} />
                    <meta name="googlebot" content={content.metaGoogleBot} />
                    <meta name="keywords" content={content.metaKeywords} />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
                <div className="container-fluid bg-light py-3" id="VoucherPage">
                    <div className="container-xl rounded py-1">
                        <div className="row voucherHeader" style={{backgroundImage: "URL('" + headerImage + "')"}}>
                            <div className="col-sm-6">&nbsp;</div>
                            <div className="col-sm-6 py-5 text-white">
                                <div className="no-mobile"><br /><br /><br /></div>
                                <h2><img className="small--hide"
                                    src="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086"
                                    srcSet="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x, //cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x"
                                    height="40" alt="Overnight Flowers" itemProp="logo" /> x {' '} alpha m.
                                </h2>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-4 text-left">
                            <div className="col-sm-8">
                                <div className="card voucher">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-6 p-3 px-5">
                                                <h3 className="h2 py-3">{title}</h3>
                                                <ul className="px-0">
                                                    <li>{items[1].list1}</li>
                                                    <li>{items[1].list2}</li>
                                                    <li>{items[1].list3}</li>
                                                    <li>{items[1].list4}</li>
                                                    <li>{items[1].list5}</li>
                                                </ul>
                                                <h3 className="h5 text-center">Retail Price: <span style={{textDecoration: 'line-through'}}>${items[1].priceRetail}</span></h3>
                                                <h3 className="h2 text-center text-red">${items[1].price} <span class="badge badge-danger">61% OFF</span></h3>
                                                <br/>
                                                <a href={"/purchase/" + this.props.voucherId + "/1"} className="btn btn-block btn-warning" style={{fontWeight: 'bold'}}>PURCHASE THE VOUCHER</a>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-6 p-1">
                                                            <img src={items[1].image1} alt={"image of " + items[1].title} className="img-fluid"/>
                                                        </div>
                                                        <div className="col-6 p-1">
                                                            <img src={items[1].image2} alt={"image of " + items[1].title} className="img-fluid"/>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 p-1">
                                                            <img src={items[1].image3} alt={"image of " + items[1].title} className="img-fluid"/>
                                                        </div>
                                                        <div className="col-6 p-1">
                                                            <img src={items[1].image4} alt={"image of " + items[1].title} className="img-fluid"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12"><h3 className="h2 text-center text-warning py-5">How It Works</h3></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 text-center my-4">
                                <img src="/images/onf-icon.png" alt="Overnight Flowers" className="img-fluid" border="0" />
                                <div className="helpfulBall my-4">1</div>
                                <h3 className="h1">{step1}</h3>
                            </div>
                            <div className="col-sm-4 text-center my-4">
                                <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    style={{ fontSize: '6em' }}
                                    />
                                <div className="helpfulBall my-4">2</div>
                                <h3 className="h1">{step2}</h3>
                            </div>
                            <div className="col-sm-4 text-center my-4">
                                <FontAwesomeIcon
                                    icon={faShippingFast}
                                    style={{ fontSize: '6em' }}
                                    />
                                <div className="helpfulBall my-4">3</div>
                                <h3 className="h1">{step3}</h3>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-fluid bg-white py-5">
                    <div className="container-xl">
                        <div className="row helpfulInformation">
                            <div className="col-12"><h3 className="h2 text-warning py-4">Helpful Information</h3></div>
                            <div className="col-12 small" dangerouslySetInnerHTML={{__html: helpfulInformation }} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

    }
}

export default ContentPage