import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faClock, faEnvelope} from '@fortawesome/free-solid-svg-icons'

class Header extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <header style={{borderBottom: '1px solid #e8e8e1'}}>
                <div className="container-fluid py-3" style={{borderBottom: '1px solid #ababab'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-sm-8 text-dark">
                                <FontAwesomeIcon
                                    icon={faClock}
                                /> Order before 2:30PM EST for Free Next Day Delivery on Tuesday - Saturday
                            </div>
                            <div className="col-sm-4 text-right"> | &nbsp; <a href="https://www.overnightflowers.com/pages/contact-us" className="text-dark">
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                /> Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar topnav container-xl py-4 navbar-expand-lg navbar-light bg-white">
                    <a className="navbar-brand" href="https://www.overnightflowers.com/"><img className="small--hide" src="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086" srcSet="//cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x, //cdn.shopify.com/s/files/1/0224/1377/0816/t/18/assets/onflogo.svg?18086 2x" height="40" alt="Overnight Flowers" itemProp="logo" /></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsMainNav" aria-controls="navbarsMainNav" aria-expanded="false"  aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
                    <div className="collapse navbar-collapse" id="navbarsMainNav">
                        <ul className="navbar-nav mr-auto text-large">
                            <li className="nav-item"><a className="nav-link" href="https://www.overnightflowers.com/collections/all-products">Shop</a></li>
                            <li className="nav-item"><a className="nav-link" href="https://www.overnightflowers.com/pages/holiday-collection">Holiday Collection</a></li>
                            <li className="nav-item"><a className="nav-link" href="https://www.overnightflowers.com/collections/assortments">Mixed Bouquets</a></li>
                            <li className="nav-item"><a className="nav-link" href="https://www.overnightflowers.com/collections/roses">Roses</a></li>
                            <li className="nav-item"><a className="nav-link" href="https://www.overnightflowers.com/pages/the-onf-story">About Us</a></li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Header